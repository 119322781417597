import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import MatrixComponent from '@/components/Matrix/Matrix.vue'
import QuadrantComponent from '@/components/Quadrant/Quadrant.vue'
import QuadrantNameComponent from '@/components/Quadrant/QuadrantName.vue'
import * as toast from '@/services/toast'
import http from '@/services'
import collectionModule from '@/store/collection'
import matrixModule from '@/store/matrix'
import { Quadrant } from '@/Models'
import authModule from '@/store/auth'

interface quadrants {
  north_quadrant: Quadrant
  south_quadrant: Quadrant
  west_quadrant: Quadrant
  east_quadrant: Quadrant
}
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    MatrixComponent,
    QuadrantComponent,
    QuadrantNameComponent,
  },
})
export default class Matrix extends Vue {
  activeStep = 0
  customNavigation = false
  prevIcon = 'chevron-left'
  nextIcon = 'chevron-right'
  collection_id = 0
  quadrants: quadrants = {
    north_quadrant: { name: '' } as Quadrant,
    east_quadrant: { name: '' } as Quadrant,
    south_quadrant: { name: '' } as Quadrant,
    west_quadrant: { name: '' } as Quadrant,
  }
  isEdit = false

  mounted() {
    if (collectionModule.collection_id) {
      this.collection_id = collectionModule.collection_id
    } else this.$router.push('/hoshin')
    if (!matrixModule.isEdit) {
      this.quadrants = {
        north_quadrant: { name: '' } as Quadrant,
        east_quadrant: { name: '' } as Quadrant,
        south_quadrant: { name: '' } as Quadrant,
        west_quadrant: { name: '' } as Quadrant,
      }
    } else this.quadrants = matrixModule.matrix as quadrants
    this.isEdit = matrixModule.isEdit
  }

  async next_action() {
    if (this.activeStep == 0) {
      if (this.$refs[`matrix_${this.activeStep}`]) {
        const element = this.$refs[`matrix_${this.activeStep}`] as any
        const element2 = this.$refs[`matrix_c${this.activeStep}`] as any
        const data = await element.validate()
        if (data) matrixModule.setMatrix(data)
        const data2 = await element2.validate()
        if (data && data2) {
          matrixModule.setMatrix(data2)
          if (this.activeStep == 0) this.quadrants = data2 as quadrants
          this.activeStep++
        }
      }
    } else if (this.$refs[`matrix_${this.activeStep}`]) {
      const element = this.$refs[`matrix_${this.activeStep}`] as any
      const data = await element.validate()
      if (data) {
        matrixModule.setMatrix(data)
        this.activeStep++
      }
    }
  }

  previous_action() {
    this.activeStep--
  }

  async action() {
    try {
      this.isEdit ? await this.edit() : await this.save()
      matrixModule.clear()
      this.$router
        .push({
          path: '/matrices',
          query: { collection_id: this.collection_id + '' },
        })
        .catch(() => console.log)
    } catch (error) {
      toast.error(this.$i18n.t('toast.generic_error').toString())
      console.error(error)
    }
  }

  async save() {
    const user_id = authModule.ID
    matrixModule.setMatrix({
      collection_id: this.collection_id,
      user_id: user_id,
    })
    const matrix = matrixModule.matrix
    if (matrix) {
      const url = 'hoshin/matrix_action/'
      await http.post(url, matrix)
    } else throw new Error('Matrix is null')
  }

  async edit() {
    const matrix = matrixModule.matrix
    if (matrix) {
      const url = `hoshin/matrix_action/${matrix.id}/`
      await http.put(url, matrix)
    } else throw new Error('Matrix is null')
  }
}
